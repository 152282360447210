import styled, {css}  from "styled-components";
const MediumFont = 'Rubik-Medium';
const BoldFont = 'Rubik-Bold';
const RegularFont = 'Rubik-Regular';
const MainSurveyContainer = styled.div`
  width: 100%;
  padding: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @media(max-width: 830px){
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const SurveyTitle = styled.div`
  width: 100%;
  text-align: center;
  padding: 10px; 
  padding-top: 50px;
  > div: first-child{
    color: #005C87;
    font-family: ${MediumFont};
    font-size: 32px;
    font-weight:500;
    text-transform: capitalize;
  }
  /* > div: last-child{
    color: #67676A;
    font-family: ${MediumFont};
    font-size: 14px;
    opacity: 0.5;
  } */
`;
const SurveySideMenu = styled.div`
  width: 30%;
  padding: 0 10px 0 0;
  @media (max-width: 830px){
    width: 60%;
  }
  @media (max-width: 500px){
    width: 100%;
    padding: 10px;
  }
`;
const SurveyQuestionsContainer = styled.div`
  width: ${({width}) => width ? 'calc(70% - 40px)' : '60%'};
  margin: ${({width}) => width ? '0 20px' : '0 auto'};
  @media (max-width: 830px){
    width: ${({width}) => width ? '60%' : '100%'};
    margin: 0 auto;
  }
  @media (max-width: 500px){
   width: 100%;
   margin: 0 auto;
   padding: 10px;
  }
`;
const CategoriesContainer = styled.button`
  width: 100%;
  padding: 20px;
  background-color: ${({completed}) => completed ? '#1ac6be' : '#fff'};
  border-radius: 6px;
  cursor: pointer;
  outline: none;
  border: none;
  margin-bottom: 4px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  text-transform: capitalize;
  > div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div: first-child{
      text-align: left;
      width: 70%;
      > img{
        width: 40px;
      }
      > span{
        color: #282C37;
        font-family: ${MediumFont};
        font-size: 14px;
        text-transform: capitalize;
        @media(max-width: 340px){
          margin-left: 1px;
        }
      }
    }
    > div: last-child{
      width: 30%;
      font-family: ${RegularFont};
      color: #6D819C;
      font-size: 14px;
      text-transform: capitalize;
    }
  }
  ${({ disabled }) => disabled === 1 && css`
    opacity: 0.4;
    cursor: not-allowed !important;
  `}
  ${({ active }) => active === true && css`
    background-color: #E9F3FB;
    border: 2px solid #1E76AB;
  `}
`;
const RenderSurveyQuestions = styled.div`
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    float: left;
    margin: ${({width}) => width ? '0 0 30px 15px' : 'auto'};
    align-items: center;
    padding: 0 10px;
    @media (max-width: 830px){
     padding: ${({width}) => width ? '0px' : '0 10px'};
     margin:  ${({width}) => width ? '10px 0' : 'auto'};
    }
    @media (max-width: 664px){
     width: 100%;
     margin: 0px;
    }
`;
const RenderQuestionContainer = styled.div`
  padding: ${({width}) => width ? '45px' : '30px 50px'};
  width: 100%;
  border-radius: 6px;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.08);
  @media(max-width: 670px){
    padding: 10px 20px;
  }
`;
const QuestionContainer = styled.div`
  padding-bottom: 15px;
  display: flex;
  flex-direction: column;
  >div: first-child{
    font-size: 15px;
    opacity: 0.7;
  }
  >div {
    color: #484856;
    font-family: ${MediumFont};
    font-size: 17px;
  }
  @media(max-width: 500px){
    min-height: 120px;
  }
`;

const OptionContainer = styled.div`
  border: 1px solid rgba(103, 103, 106, 0.5);
  box-sizing: border-box;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  margin: 10px 0;
  width: 100%;
  height: 53px;
  > div: first-child{
    width: 20%;
    display: flex;
    justify-content: center;
  }
  > div: last-child{
    width: 80%;
    font-size: 18px;
    color: #67676A;
    font-family: ${RegularFont}
    padding-top: 3px;
  }
  ${({ isActive }) => isActive && css`
    background-color: ${({bgColor}) => bgColor ? bgColor : '#4798d9'};
    color: #fff;
    border: none;
    > div: first-child {
      >div{
        display: flex;
      }
    }
    > div: last-child {
      color: #fff;
      font-family: ${MediumFont}
    }
  `}
`;

const CheckMark = styled.span`
  margin-bottom: 3px;
  &::after {
    content: "";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 8px;
    height: 14px;
    border-style: solid;
    border-color: ${({bgColor}) => bgColor ? bgColor : '#4798d9'};
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top-color: transparent;
    border-left-color: transparent;
  }
`;
const CheckMarkContainer = styled.div`
  display: none;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  background-color: #fff;
  align-items: center;
  justify-content: center;
`;
const ProgressBarContainer = styled.div`
  Width: 100%;
  height: 4px;
  background-color: rgba(196, 100, 172, 0.2);
  border-radius: 2px 2px 0px 0px;
`;
const FillProgressBar = styled.div`
  width: ${({fillWidth})=> fillWidth && fillWidth}
  background-color: #C464AC;
  height: 4px;
  border-radius: 2px;
`;
const ButtonContainer = styled.div`
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: ${({justify}) => justify ? 'flex-end' : 'space-between'};
  margin: ${({margin}) => margin ? '0 0 30px 15px' : '15px 0'};
  @media (max-width: 664px){
    margin: 10px 0px;
  }
`;
const SubmitSurvey = styled.button`
  width: 110px;
  height: 35px;
  background-color: #E97573;
  color: #FFFFFF;
  border-radius: 25px;
  border: none;
  outline: none;
  font-family: ${BoldFont};
  ${({ disabled }) => disabled === true && css`
   opacity: 0.5;
   cursor: not-allowed !important;
 `}
`;
const TextAreaContainer = styled.div`
  width: 100%;
`;
export {MainSurveyContainer, SurveySideMenu, SurveyTitle, SurveyQuestionsContainer, CategoriesContainer, QuestionContainer, RenderQuestionContainer, OptionContainer, CheckMark, CheckMarkContainer, ProgressBarContainer, FillProgressBar, SubmitSurvey, RenderSurveyQuestions, ButtonContainer, TextAreaContainer};